.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Neutral-80);
  color: var(--Neutral-00);
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-transform: lowercase;
  cursor: pointer;
  animation: opacity-pulse 3s ease-in infinite;
  transition: all 0.2s ease-in-out;
  width: 48px;
  height: 48px;
  border-radius: 18px;
  border: 1px solid rgba(0, 0, 0, 0);
  user-select: none;
  cursor: pointer;

  &:focus {
    border: 2px solid var(--Neutral-20);
    outline: 2px solid var(--Neutral-80);
  }

  &.outlined {
    background: var(--Neutral-2);
    border: 1px solid var(--Neutral-20);
  }

  .no-action {
    pointer-events: none;
  }

  &:hover {
    background: var(--Neutral-60);
    border: 1px solid var(--Neutral-20);
  }

  &.connected {
    background: var(--Blue-800);
    color: var(--Blue-500);

    &:hover {
      border: 1px solid var(--Blue-500);
    }
  }
}

@property --volume {
  syntax: "length";
  inherit: false;
  initial-value: 0px;
}

.disabled .mic-button,
.mic-button.disabled {
  &:before {
    background: rgba(0, 0, 0, 0);
  }
}

.mic-button {
  position: relative;
  background-color: var(--accent-red);
  z-index: 1;
  color: black;
  transition: all 0.2s ease-in;

  &:focus {
    border: 2px solid var(--Neutral-20);
    outline: 2px solid var(--Red-500);
  }

  &:hover {
    background-color: var(--Red-400);
  }

  &:before {
    position: absolute;
    z-index: -1;
    top: calc(var(--volume) * -1);
    left: calc(var(--volume) * -1);
    display: block;
    content: "";
    opacity: 0.35;
    background-color: var(--Red-500);
    width: calc(100% + var(--volume) * 2);
    height: calc(100% + var(--volume) * 2);
    border-radius: 24px;
    transition: all 0.02s ease-in-out;
  }
}

.connect-toggle {
  &:focus {
    border: 2px solid var(--Neutral-20);
    outline: 2px solid var(--Neutral-80);
  }

  &:not(.connected) {
    background-color: var(--Blue-500);
    color: var(--Neutral-5);
  }
}

.control-tray {
  background: var(--Neutral-90);
  color: var(--Neutral-00);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  // border-radius: 15px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 350px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  gap: 20px;
  margin-bottom: 70px;

  .actions-nav {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 100px;
  }

  .action-button {
    background: var(--Neutral-80);
    color: var(--Neutral-00);
    border: none;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: var(--Neutral-60);
    }
  }

  .connection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-top: 70px;

    .connection-button-container {
      background: var(--Neutral-80);
      border-radius: 10px;
      // padding: 10px;
    }

    .text-indicator {
      font-size: 14px;
      color: var(--Neutral-00);
      margin-top: 5px;
    }
  }

  .connected-text {
    color: white;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }
}

.actions-nav {
  border-radius: 27px;
  display: inline-flex;
  gap: 12px;
  align-items: center;
  overflow: clip;
  padding: 10px;

  transition: all 0.6s ease-in;

  &>* {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
  .connected-text{
    color: white;
    position: absolute;
    top: 20;
  }
}

@keyframes opacity-pulse {
  0% {
    opacity: 0.9;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.9;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}

body {
  background: #F5F5F5;
}

a {
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}

.container {
  max-width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

.btn {
  background: #FFF;
  color: #212121;
  padding: 14px 25px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn img {
  width: 20px;
  margin-left: 10px;
}

.btn.dark-btn {
  background: #212EA0;
  color: #fff;
}

.admin-content-wrapper {
  width: 85%;
  background-color: #fff;
  border-end-start-radius: 1rem;
  border-start-start-radius: 1rem;
  margin-top: .5rem;
}

/* ----- media query ----------- */

@media (max-width: 1200px) {
  .container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .btn {
    padding: 14px 22px;
  }
}

@media (max-width: 991.98px) {
  .admin-content-wrapper {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
  }
}
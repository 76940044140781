/* .label-input-pair {
  display: flex;
  align-items: center;
  gap: 10px;
} */

.button-group {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}



/* Customize the slider track */
.ant-slider-track {
    background-color: #168900 !important;
  }
  
  /* Customize the slider handle */
  .ant-slider-handle {
    border-color: #168900 !important;
  }
  
  /* Customize the slider rail (optional) */
  .ant-slider-rail {
    background-color: #ffd1b3 !important; /* A lighter shade of the slider color for contrast */
  }
  
.admin-prompt-list-table {
    margin-top: 30px;
    background-color: white;
    border-radius: 10px;
    padding: 40px 1rem;
    padding: 1rem;
    border: 1px solid lightblue;
}

.prompt-update-container {
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
}

.prompt-form-edit-wrapper {
    display: flex;
    margin: auto;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin-top: 60px;
}

.add-new-prompt-btn {
    margin-left: auto;
    display: flex;
}

.prompt-version-wrapper {
    min-height: 100px;
    max-height: 320px;
    border-radius: 8px;
    margin-bottom: 1rem;
    margin-top: 10px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid lightskyblue;
}
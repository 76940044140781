.admin-user-list-table {
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
    border: 1px solid lightblue;

}

.table-pagination-footer .ant-pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
}
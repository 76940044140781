.chatBack {
  width: 100%;
  min-height: 100vh;
  background: black;
}

.chat-wrapper {
  max-width: 98%;
}

.custom-divider {
  border-color: #6b7280;
  /* Tailwind gray-500 color */
}

.ai-chat {
  background: linear-gradient(200deg,
      rgb(255, 95, 31),
      rgb(241 245 249) 50.71%);
}

.user-chat {
  background: linear-gradient(200deg, rgb(30, 35, 58), rgb(241 245 249) 50.71%);
}

.input-highlight {
  border-color: #168900;
  /* Highlight border color */
  box-shadow: 0 0 10px #168900;
  border-radius: 1rem;
  /* Optional shadow for additional highlight effect */
}

/* Default background color for the checkbox when not checked */
.ant-checkbox-inner {
  background-color: transparent !important;
  /* Red background */
  border-color: #ccc !important;
}

/* Background color when checkbox is checked */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #168900 !important;
  border-color: #168900;
}

/* Background color when checkbox is indeterminate */
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #168900;
}

/* Hover and focus states */
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #168900 !important;
}

/* input:focus, textarea:focus
{
    border: 1px solid #ef6a36;
    outline-offset: 0px;
    outline: none;
} */

.dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #168900;
  color: #168900;
  box-shadow: 9999px 0 0 0 #168900;
  animation: dot-falling 1s infinite linear;
  animation-delay: 0.1s;
}

.dot-falling::before,
.dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #168900;
  color: #168900;
  animation: dot-falling-before 1s infinite linear;
  animation-delay: 0s;
}

.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #168900;
  color: #168900;
  animation: dot-falling-after 1s infinite linear;
  animation-delay: 0.2s;
}

@keyframes dot-falling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }

  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 #168900;
  }

  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dot-falling-before {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }

  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 #168900;
  }

  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dot-falling-after {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }

  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 #168900;
  }

  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

.custom-modal .ant-modal-content {
  width: 600px !important;
  /* Set the width of the modal */
  height: 400px !important;
  /* Set the height of the modal */
  display: flex;
  flex-direction: column;
}

.custom-modal .ant-modal-body {
  flex: 1;
  /* Allow the body to grow and take up available space */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.custom-modal .ant-modal-header {
  background-color: #f7f7f7 !important;
  padding: 10px 24px !important;
}

.custom-modal-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.modal-body-content {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.modal-footer {
  background-color: white;
  padding: 10px 24px;
  text-align: right;
  border-top: 1px solid #e8e8e8;
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.hansel-modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 100;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: fit-content;
  background-color: white;
  border-radius: 1rem;
  border: 1px solid #d0d5dd;
  box-shadow: 0 2px 13px 0 #168900;
}

/* Modal Content/Box */
.hansel-modal-content {
  background-color: #fefefe;
  /*margin: 15% auto; /* 15% from the top and centered */
  padding: 16px;
  /*border: 1px solid #888;*/
  height: 100vh;
  width: 100%;
  /* Could be more or less, depending on screen size */
}

.openModal {
  display: block;
}

.chat-wrap-container {
  max-width: 90%;
}

.chat-history-wrapper {
  max-width: 100%;
}

.scroll-chat-btn {
  position: absolute;
  right: 40px;
  background-color: white;
  padding: 5px;
  border-radius: 50%;
  color: #a4a1a1;
  font-size: 20px;
}

.scroll-chat-bottom-btn {
  bottom: 140px;
}

.scroll-chat-reload-btn {
  bottom: 200px;
}

@media (min-width: 640px) and (max-width: 1024px) {
  .openModal {
    width: 50%;
    left: 50%;
  }

  .hansel-modal {
    margin-left: 20px;
    margin-left: 20px;
    max-width: 45%;
  }
}

@media (max-width: 768px) {
  .custom-modal .ant-modal {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
  }

  .custom-modal .ant-modal-content {
    width: 100% !important;
    /* Set the width of the modal */
    height: 100% !important;
    /* Set the height of the modal */
  }

  .custom-modal .ant-modal-body {
    height: calc(100% - 55px);
    /* Adjust based on your header/footer height */
    overflow-y: auto;
  }

  .chat-history-wrapper {
    margin-left: 1rem;
  }

  .scroll-chat-btn {
    right: 30px;
  }

}

@media screen and (max-width: 575.98px) {

  .iphone-chat-wrapper {
    height: 86vh;
    /* Apply this only on iPhones */
  }

  .hansel-modal {
    max-width: 95%;
    margin: 0 .5rem;
  }

  .chat-history-wrapper {
    margin-left: .5rem;
  }

  .scroll-chat-btn {
    right: 20px;
  }

  .scroll-chat-btn-iphone {
    bottom: 280px;
  }

  .scroll-chat-reload-btn-iphone {
    bottom: 320px;
  }
}
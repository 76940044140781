.chat-menu-title {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: left;
    margin-bottom: 30px;
}

.chat-selection-menu-wrapper {
    display: flex;
    gap: 20px;
    padding: 10px 0;
    align-items: center;
}


.check-box-color-div {
    width: 24px;
    height: 20.4px;
    border-radius: 8px;
    border: 0.3px;
}

.chat-selection-menu-title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
    width: 75%;
}

.chat-menu-checkbox .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-model-dropdown .ant-dropdown-menu {
  border: 1px solid #d1d5db; /* Black border, you can customize the color and style */
  border-radius: 4px; /* Optional: Adds rounded corners */
}

.custom-border {
  border: 2px solid #d9d9d9; /* Adjust the color and width as needed */
  padding: 6px 18px; /* Adjust padding as needed */
  border-radius: 4px; /* Optional: adds rounded corners */
  display: inline-block; /* Ensure the border wraps around the content properly */
}
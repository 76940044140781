.signupContainer {
  background-color: black; /* Change background color to black */
  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 560px;
  color: white; /* Ensure text is visible against the black background */
}

.signupContainer p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  margin: 20px 0;
  color: white; /* Ensure text is visible against the black background */
}

.signupContainer span {
  font-size: 12px;
  color: white; /* Ensure text is visible against the black background */
}

.signupContainer a {
  font-size: 13px;
  text-decoration: none;
  margin: 15px 0 10px;
}

.signupContainer button {
  background-color: #228B22;
  color: #fff;
  font-size: 12px;
  padding: 10px 45px;
  /* border: 1px solid white; */
  border-radius: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  /* text-transform: uppercase; */
  margin-top: 10px;
  cursor: pointer;
  width: 100%; /* Make buttons full width */
}

.signupContainer button.hidden {
  background-color: transparent;
  border-color: #fff;
}

.signupContainer form {
  background-color: black; /* Ensure form background matches container background */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  height: 100%;
}

.signupContainer input {
  background-color: #eee;
  border: none;
  margin: 8px 0;
  padding: 10px 15px;
  font-size: 13px;
  border-radius: 8px;
  outline: none;
}

.form-signupContainer {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in {
  left: 0;
  z-index: 2;
}

.signupContainer.active .sign-in {
  transform: translateX(100%);
}

.sign-up {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.signupContainer.active .sign-up {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: move 0.6s;
}

@media (max-width: 768px) {
  .signupContainer.active .sign-up {
    transform: none;
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
  }
}

button:disabled {
  background-color: #cccccc; /* Light grey */
  color: #666666; /* Dark grey */
  cursor: not-allowed;
}

@keyframes move {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }
  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.social-icons {
  margin: 20px 0;
}

.social-icons a {
  border: 1px solid #ccc;
  border-radius: 20%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  width: 40px;
  height: 40px;
}

.toggle-signupContainer {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
  border-radius: 150px 0 0 100px;
  z-index: 1000;
}

.signupContainer.active .toggle-signupContainer {
  transform: translateX(-100%);
  border-radius: 0 150px 100px 0;
}

.toggle {
  background-color: #f5a623;
  height: 100%;
  background: linear-gradient(to right, #006400, #000000);
  color: #fff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.signupContainer.active .toggle {
  transform: translateX(50%);
}

.toggle-panel {
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
  text-align: center;
  top: 0;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.toggle-left {
  transform: translateX(-200%);
}

.signupContainer.active .toggle-left {
  transform: translateX(0);
}

.toggle-right {
  right: 0;
  transform: translateX(0);
}

.signupContainer.active .toggle-right {
  transform: translateX(200%);
}
.signupContainer input {
  background-color: #333; /* Change to a light gray shade */
  border: none;
  margin: 8px 0;
  padding: 10px 15px;
  font-size: 13px;
  border-radius: 8px;
  outline: none;
}
.forgot-password-container {
  width: 100%; /* Ensure the container takes full width */
  display: flex;
  justify-content: flex-start; /* Aligns content to the left */
}

.forgot-password-link {
  display: block;
  color: white; /* Ensure the link color contrasts with the background */
  font-size: 13px; /* Ensure font size matches the design */
  text-decoration: none; /* Remove underline if desired */
}
